<template>
    <div>
        <div class="top-container">
            <div class="top-tool-btn-wrapper">
                <div class="left">
                    <GgdCard background="#409EFF" title="今日流水" :handleClick="historyRecord" handleShadow="hover"
                             :content="(analysisData.revenue / 100).toFixed(2)"></GgdCard>
                    <GgdCard background="#FF88A7" title="拨打时长"
                             :content="formateTime(analysisData.calledTotal)"></GgdCard>
                    <GgdCard background="#7B5EEA" title="今日拨打量" :content="analysisData.called" unit="个"></GgdCard>
                    <GgdCard background="#F19149" title="领取机会数"
                             :content="analysisData.receiveFirst + analysisData.receiveInventory" unit="个"></GgdCard>
                    <GgdCard background="#CDB6FFFF" title="补单流水" v-if="operates['repair']"
                             :content="(analysisData.repairRevenue / 100).toFixed(2)"></GgdCard>
                </div>

                <div class="right">
                    <el-tooltip v-if="operates['problem']" class="item" effect="dark"
                                content="班主任及学习问题等均可在此反馈，收到问题会及时处理并进行反馈"
                                placement="bottom">
                        <el-button type="primary" size="medium" class="clearStream" @click="clearStream">问题反馈
                        </el-button>
                    </el-tooltip>

                    <div class="_btn-group">
                        <el-button type="primary" size="mini" v-if="operates['first']"
                                   :style="{margin:'0 15px 0 0',background:'#FFD321',borderColor:'#FFD321'}"
                                   @click="loadNewClient">
                            领取首咨
                        </el-button>
                        <el-button type="primary" size="mini" v-if="operates['sea']"
                                   :disabled="mainInlet.indexOf(3) === -1"
                                   @click="toRouter('Stock',{name:'领取库存'})"
                                   :style="{margin:'0 15px 0 0',background:'#FFD321',borderColor:'#FFD321'}">领取库存
                        </el-button>
                        <el-button type="success" size="mini" @click="repairOrder" v-if="operates['repair']"
                                   :style="{margin: '0 15px 0 0'}">订单补录
                        </el-button>
                        <el-button v-if="isZebj" type="primary" size="mini" @click="feedbackQuestion(5)"
                                   :style="{margin: '0 15px 0 0'}">活动同步表
                        </el-button>
                        <el-button v-if="isZebj" type="success" size="mini" @click="feedbackQuestion(6)"
                                   :style="{margin: '0 15px 0 0',background:'#f33d3d', border: 'none'}">crm使用操作手册
                        </el-button>
                        <el-button v-if="isZebj" type="success" size="mini" @click="createCoupon(true)"
                                   :style="{margin: '0 15px 0 0',background:'#3de4f3', border: 'none'}">发放优惠券
                        </el-button>
                    </div>
                </div>
            </div>

            <el-row type="flex" justify="start" align="left" style="flex-wrap: wrap">
                <el-col :span="1"></el-col>
                <el-tooltip v-for="(itemBtn,indexBtn) in myBtnList" effect="dark"
                            :content="itemBtn.remarks"
                            placement="top">
                    <el-badge :value="messageType_13_id.indexOf(itemBtn.id) > -1 ? 'new' : ''" class="item">
                        <el-button :type="typeList[indexBtn%4]" size="small"
                                   @click="toRouter('StockReceive', {id: itemBtn.id,name:itemBtn.label})">
                            {{ itemBtn.label }}
                        </el-button>
                    </el-badge>
                </el-tooltip>

                <el-col :span="2"></el-col>
            </el-row>
            <el-row type="flex" align="center">
                <el-col :span="18">
                    <GgdCondition :subjectList="subjectList" :possessList="possessList" :data="searchList"
                                  :opporTypeRadios="opporTypeRadios"
                                  @doSearch="doSearch"
                                  :style="{marginLeft:'25px'}"></GgdCondition>
                    <!--                        :SearchNewInformationList="SearchNewInformationList"-->
                </el-col>
                <el-col :span="5" v-if="operates['checked']">
                    <div>
                        <span :style="{color:'#F56C6C'}">机会校验</span>
                        <el-input placeholder="请输入内容" v-model="checkOpporTelephone"
                                  @keyup.enter.native="checkOppor">
                            <template #append>
                                <el-button icon="el-icon-search" @click="checkOppor"></el-button>
                            </template>
                        </el-input>
                    </div>
                    <div v-if="isZebj">
                        <el-tooltip class="item" effect="dark"
                                    content="可查询2022年一建购买正价课学员，是否符合重修条件及学员报考地点是否延考"
                                    placement="bottom">
                            <div slot="content">可查询2022年一建购买正价课学员，<br/>是否符合重修条件及学员报考地点是否延考<br/><b
                                    style="color: #ff5d5d">如果需要登录，请重新点击</b></div>
                            <el-button size="mini" type="success">
                                <template>
                                    <a style="text-decoration: none;outline: none;color: #fafafa;"
                                       href="https://qingflow.com/s/cc775ee7"
                                       target="_blank">重修学员查询</a>
                                </template>
                            </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" placement="bottom">
                            <div slot="content">抖音购买低价引流品课，<br/>未在抖店显示订单号的商品，<br/>可在此查看资料订单号<br/><b
                                    style="color: #ff5d5d">如果需要登录，请重新点击</b></div>
                            <el-button size="mini" type="primary">
                                <template>
                                    <a style="text-decoration: none;outline: none;color: #fafafa;"
                                       href="https://qingflow.com/s/256b5fa2"
                                       target="_blank">小客单快递查询</a>
                                </template>
                            </el-button>
                        </el-tooltip>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div style="margin-top: 5px">
            <el-row>
                <el-link :type="message.isRead === 0 ? 'danger' : 'info'" @click="openNoticePopup"> {{
                    message.title
                    }}
                </el-link>
                <!--                <el-button type="danger" icon="el-icon-delete" size="mini" circle plain></el-button>-->
            </el-row>
        </div>

        <el-row type="flex" justify="space-between" :style="{marginTop:'5px'}">
            <el-col :span="18">
                <el-radio-group size="mini" v-model="opporTypeRadios" @change="doSearchByType">
                    <el-radio-button border="true" size="mini" label="">全部</el-radio-button>
                    <el-radio-button border="true" size="mini" label="1">首咨</el-radio-button>
                    <el-radio-button border="true" size="mini" label="2">个人</el-radio-button>
                    <el-radio-button border="true" size="mini" label="3">常规</el-radio-button>
                    <el-radio-button border="true" size="mini" label="4">库存</el-radio-button>
                    <el-radio-button border="true" size="mini" label="5">分配</el-radio-button>
                    <el-radio-button border="true" size="mini" label="6">预约</el-radio-button>
                    <el-radio-button border="true" size="mini" label="7">唤醒</el-radio-button>
                    <el-radio-button border="true" size="mini" label="10">待添加微信</el-radio-button>
                    <el-radio-button border="true" size="mini" label="8">2天内回库机会<span
                            v-if="withinTwoDayBackNum !== null">:({{ withinTwoDayBackNum }})</span>
                    </el-radio-button>
                    <el-radio-button border="true" size="mini" label="9">今日回库机会<span
                            v-if="withinTodayBackNum !== null">:({{ withinTodayBackNum }})</span></el-radio-button>
                </el-radio-group>
            </el-col>
            <el-col :span="6">
                <el-button-group>
                    <el-button type="primary" size="mini" v-if="operates['backsea']" @click="openBackSync(null)">批量回库
                    </el-button>
                    <el-button type="primary" size="mini" v-if="operates['addop']"
                               :disabled="mainInlet.indexOf(1) === -1"
                               @click="openAddOppor">添加机会
                    </el-button>
                    <el-button type="primary" size="mini" v-if="operates['addop']" @click="openWakeOpportunityBank">唤醒数据
                    </el-button>
                    <el-button v-permission="'crm:opportunity:importBack'" type="primary" size="mini"
                               @click="importObj.showObj.isShow = true">导入批量回库
                    </el-button>
                </el-button-group>
            </el-col>
        </el-row>

        <el-table :data="tableData" max-height="506px" @sort-change="handleSortChange"
                  :default-sort="sortRule" v-loading="loading" :row-class-name="handleRowClassName"
                  @select="selectRow" @select-all="selectAll" @selection-change="selectChange">
            <el-table-column align="center" type="selection" fixed="left"></el-table-column>
            <!--      <el-table-column align="center" prop="subjectIds" label="咨询科目">-->
            <!--          <template slot-scope="scope">-->
            <!--              <div v-for="item in subjectList" v-if="item.id+'' === scope.row.subjectIds">{{item.subjectName}}</div>-->
            <!--          </template>-->
            <!--      </el-table-column>-->
            <el-table-column v-for="(col,index) in columns" :prop="col.name"
                             :sortable="col.name.indexOf('Time') > 0"
                             :fixed="col.name === 'telephone'"
                             :label="col.label"
                             width="150" align="center">
                <template slot-scope="scope">
                    <div v-if="col.name === 'subjectId'">
                        <span v-for="item in subjectList"
                              v-if="item.id+'' === scope.row.subjectIds">{{ item.subjectName }}</span>
                    </div>
                    <div v-if="col.name === 'telephone'" class="telephone">
                        <el-tooltip class="item" effect="dark" content="已拨打未接通" placement="bottom"
                                    v-if="scope.row.dialingStatus === 2">
                            <i class="el-icon-phone-outline red"
                               style="font-size: 19px;vertical-align:middle;margin-right: 4px"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="已拨打已沟通" placement="bottom"
                                    v-if="scope.row.dialingStatus === 1">
                            <img src="../../assets/icon/calling.png" alt=""
                                 style="vertical-align: middle;margin-top: -4px;margin-right: 4px">
                        </el-tooltip>
                        <span :style="scope.row.dailCountToday === 0 && scope.row.type === 1 ? {color:'red'}:''"
                              class="btn-click-open"
                              @click="openOpporDetail(scope.row.id,scope.$index)">{{ scope.row.hideTelephone }}
                    </span>
                        <el-tooltip effect="dark" content="点击复制手机号" placement="bottom"
                                    v-if="scope.row.telephone">
                            <!--              <i class="el-icon-document-copy" v-clipboard:copy="scope.row.telephone"-->
                            <!--                 v-clipboard:success="onCopy"-->
                            <!--                 v-clipboard:error="onError"></i>-->
                            <i class="el-icon-document-copy btn-click-open" @click="copyWechat(scope.row.id)"></i>
                        </el-tooltip>
                    </div>
                    <div v-else-if="col.name === 'type'">
                        <span v-if="scope.row.type === 1">首咨</span>
                        <span v-if="scope.row.type === 2">个人</span>
                        <span v-if="scope.row.type === 3">常规</span>
                        <span v-if="scope.row.type === 4">库存</span>
                        <span v-if="scope.row.type === 5">唤醒</span>
                    </div>
                    <div v-else-if="col.name === 'weiXin'">
                        <el-tooltip class="item" effect="dark" content="已添加微信" placement="bottom"
                                    v-if="scope.row.isAddWechat">
                            <i class="el-icon-check WeChatIcon"></i>
                        </el-tooltip>
                        <span>{{ scope.row.weiXin }}</span>
                        <el-tooltip class="item" effect="dark" content="点击复制微信号" placement="bottom"
                                    v-if="scope.row.weiXin">
                            <i class="el-icon-document-copy"
                               style="cursor: pointer;margin-left: 4px"
                               v-clipboard:copy="scope.row.weiXin"
                               v-clipboard:success="onCopy"
                               v-clipboard:error="onError"></i>
                        </el-tooltip>
                    </div>
                    <div v-else-if="col.name === 'subjectName'">
                        <template v-if="scope.row.subjectName" class="position">
                            <div>
                                {{ scope.row.subjectName }}
                                <span class="positionBottom" style="left: 30%">
                  <el-tooltip class="item" effect="dark" content="营销素材" placement="bottom">
                    <i class="el-icon-folder-opened green" style="font-weight: bold"
                       @click="quickAccessKnowledge('speechcraft',scope.row.subjectName)"></i>
                  </el-tooltip>
                </span>
                                <span class="positionBottom" style="left: 45%">
                  <el-tooltip class="item" effect="dark" content="师资介绍" placement="bottom">
                    <i class="el-icon-user" style="font-weight: bold;color: #0096fd"
                       @click="quickAccessKnowledge('teachers',scope.row.subjectName)"></i>
                  </el-tooltip>
                </span>
                                <span class="positionBottom" style="left: 60%">
                  <el-tooltip class="item" effect="dark" content="商品介绍" placement="bottom">
                    <i class="el-icon-s-management red" style="font-weight: bold"
                       @click="quickAccessKnowledge('course',scope.row.subjectName)"></i>
                  </el-tooltip>
                </span>
                            </div>
                        </template>
                        <span v-else>{{ scope.row.subjectName }}</span>
                    </div>
                    <div v-else-if="col.name === 'dialingStatus'">
                        <span v-if="scope.row.dialingStatus === 0">未拨打</span>
                        <span v-if="scope.row.dialingStatus === 1">已拨打已接通</span>
                        <span v-if="scope.row.dialingStatus === 2">已拨打未接通</span>
                    </div>
                    <div v-else-if="col.name === 'acquisitionMethod'">
                        <span v-if="scope.row.acquisitionMethod === 1">领取</span>
                        <span v-if="scope.row.acquisitionMethod === 2">分配</span>
                        <span v-if="scope.row.acquisitionMethod === 3">添加</span>
                    </div>
                    <div v-else-if="col.name === 'orderStatus'">
                        <span v-if="scope.row.orderStatus === 0">未成单</span>
                        <span v-if="scope.row.orderStatus === 1">已成单</span>
                        <span v-if="scope.row.orderStatus === 2">已下单</span>
                    </div>
                    <div v-else-if="col.name === 'isAppointment'">
                        <span v-if="scope.row.isAppointment === 0">无</span>
                        <span v-if="scope.row.isAppointment === 1">有</span>
                    </div>
                    <div v-else-if="col.name === 'remark'">
                        <el-tooltip class="item" effect="dark" :content="scope.row.remark" placement="top">
                            <span>{{ scope.row.remark | ellipsis }}</span>
                        </el-tooltip>
                    </div>
                    <div v-else-if="col.name === 'tags'">
                        <template v-for="item in splitTags(scope.row.tags)">
                            <el-tag v-for="(it,index) in tagsOptions" :type="typeOptions[index % 5]"
                                    v-if="it.id === item">
                                {{ it.name }}
                            </el-tag>
                        </template>
                    </div>
                    <div v-else-if="col.type === 'EXTRA'">
                        <span v-if="scope.row.tson">{{ scope.row.tson[col.name] }}</span>
                    </div>
                    <div v-else>
                        {{ showData(scope.row, col.name) }}
                    </div>
                </template>
            </el-table-column>
            <!-- 额外信息 start -->
            <!--<el-table-column v-for="(col,index) in SearchNewInformationList"
                             :prop="col.name"
                             :label="col.label"
                             width="150"
                             align="center"
                             v-if="JSON.parse(col.data).listDisplay === true">
                <template slot-scope="scope">
                    <div v-if="col.type === 'input' && scope.row.tson">
                        <span>{{scope.row.tson[col.name]}}</span>
                    </div>
                    <div v-if="col.type === 'radio' && scope.row.tson">
                        <span>{{scope.row.tson[col.name]}}</span>
                    </div>
                    <div v-if="col.type === 'checkbox'  && scope.row.tson">
                        <span>{{scope.row.tson[col.name]}}</span>
                    </div>
                </template>
            </el-table-column>-->
            <!-- 额外信息 end -->

            <el-table-column align="center" label="操作" fixed="right" min-width="300">
                <template slot-scope="scope">
                    <el-tooltip effect="dark" content="直呼拨打" placement="top"
                                v-if="callPanshi.qrcode === 'ON'">
                        <el-button v-if="trafficUser" type="success" size="mini"
                                   @click="calling($event,scope.row.id)">直呼
                        </el-button>
                    </el-tooltip>
                    <el-button hidden type="danger" size="mini" @click="hangup($event)" style="margin-left: 0">挂断
                    </el-button>
                    <el-tooltip effect="dark" :content="scope.row.call?'上次拨打:'+scope.row.call:'双呼拨打'"
                                placement="top"
                                v-if="callDouble.qrcode === 'ON'">
                        <el-button v-if="scope.row.call" @click="callDoubleing($event,scope.row.id)">已呼叫</el-button>
                        <el-button v-else type="success" size="mini"
                                   @click="callDoubleing($event,scope.row.id)">双呼
                        </el-button>
                    </el-tooltip>
                    <el-button type="primary" size="mini" :style="{backgroundColor:'#F19149',borderColor:'#F19149'}"
                               @click="openBackSync(scope.row.id)">回库
                    </el-button>
                    <el-button type="info" size="mini"
                               @click="addWeChat(scope.row)">添加微信
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.current"
                :page-sizes="[10, 20, 30, 200]"
                :page-size="page.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total">
        </el-pagination>

        <el-dialog :title="newClientTitle" :visible.sync="showClient" v-if='showClient' width="30%">
            <el-table :data="newClientData">
                <el-table-column property="id" label="首咨ID"></el-table-column>
                <el-table-column property="possessId" label="数据标识">
                    <template slot-scope="scope">
            <span v-for="poss in possessList"
                  v-show="poss.id == scope.row.possessId">{{ poss.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="mediatorId" label="来源渠道">
                    <template slot-scope="scope">
            <span v-for="mediator in mediators"
                  v-show="mediator.id == scope.row.mediatorId">{{ mediator.mediatorName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="warning" size="small" :disabled="false"
                                   @click="getNewOpportunity($event,scope.row.id)">
                            抢首咨
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-row type="flex" justify="end">
                <el-col :span="3" :pull="2">
                    <el-button type="primary" size="mini" @click="loadNewClient">换一换</el-button>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog title="机会校验列表" :visible.sync="showCheckOppor" v-if='showCheckOppor' width="88%">
            <el-table :data="checkOpporData">
                <el-table-column property="id" label="机会ID" align="center"></el-table-column>
                <el-table-column property="telephone" label="机会号码" align="center"></el-table-column>
                <el-table-column property="nickName" label="咨询师名称" align="center"></el-table-column>
                <el-table-column property="deptName" label="所在部门" align="center"></el-table-column>
                <el-table-column label="唯一标识" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.possessId === poss.id" v-for="poss in possessList">{{ poss.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="subjectName" label="项目类别" align="center"></el-table-column>
                <el-table-column label="机会状态" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.kcuserId === 0 || scope.row.kcuserId === null">未领取</span>
                        <!--            <span v-else-if="scope.row.kcuserId === userId">已领取</span>-->
                        <span v-else>已领取</span>
                    </template>
                </el-table-column>
                <el-table-column label="成交状态" align="center">
                    <template slot-scope="slot">
                        <span v-if="slot.row.orderStatus=== 1">已成交</span>
                        <span v-else>未成交</span>
                    </template>
                </el-table-column>
                <el-table-column label="领取状态" align="center">
                    <template v-slot="slot">
                        <span v-if="slot.row.showPossess && slot.row.kcuserId === 0">可领取</span>
                        <span v-else-if="!slot.row.showPossess">无操作权限</span>
                        <span v-else>机会已被领取</span>
                    </template>
                </el-table-column>
                <el-table-column property="createTime" label="创建时间" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="slot">
                        <!--            <el-button v-show="slot.row.showPossess" type="warning" size="mini"-->
                        <!--                       @click="isShowCheckOpporDetail(slot.row.id)"> 详情-->
                        <!--            </el-button>-->
                        <!--            <el-button v-show="slot.row.showPossess" type="warning" size="mini"-->
                        <!--                       @click="showTelephone(slot.row.id)">-->
                        <!--              手机号-->
                        <!--            </el-button>-->
                        <el-button v-show="slot.row.showPossess && slot.row.kcuserId === 0" v-if="slot.row.canReceive"
                                   type="warning"
                                   size="mini" :disabled="mainInlet.indexOf(2) === -1"
                                   @click="drawOppor($event,slot.row.id)">
                            领取
                        </el-button>
                        <el-button size="mini" @click="showOperation(slot.row.id)">操作记录</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <br>
        </el-dialog>

        <el-dialog v-if="isShowOperation" :visible.sync="isShowOperation">
            <History :id="chooseId"></History>
        </el-dialog>

        <!--    showCheckOpporDetail-->
        <el-dialog title="机会校验详情" :visible.sync="showCheckOpporDetail" v-if='showCheckOpporDetail' width="70%"
                   center>
            <el-table :data="showCheckOpporDetailOrder" :header-cell-style="{ 'background-color': '#f0f0f0'}">
                <el-table-column property="orderNumber" label="订单编号" width="100" align="center"></el-table-column>
                <el-table-column property="goodsName" label="商品名称" width="120" align="center"></el-table-column>
                <el-table-column property="payType" label="支付方式" width="150" align="center"></el-table-column>
                <el-table-column property="paymentAmount" label="支付价格" width="120" align="center"></el-table-column>
                <el-table-column property="createTime" label="下单时间" width="120" align="center"></el-table-column>
                <el-table-column property="accountName" label="状态" width="120" align="center"></el-table-column>
                <!--        <el-table-column property="subjectName" label="负责坐席" width="120" align="center"></el-table-column>-->
            </el-table>

            <el-table :data="showCheckOpporDetailAppoint" :header-cell-style="{ 'background-color': '#f0f0f0'}">
                <el-table-column property="userName" label="预约坐席" width="100" align="center"></el-table-column>
                <el-table-column property="create_time" label="创建时间" width="120" align="center"></el-table-column>
                <el-table-column property="mark_start_time" label="预约时间" width="150"
                                 align="center"></el-table-column>
                <el-table-column property="dial_status" label="拨打状态" width="120" align="center"></el-table-column>
                <el-table-column property="dispose_status" label="备注" width="120" align="center"></el-table-column>
            </el-table>

            <el-table :data="showCheckOpporDetailCall" :header-cell-style="{ 'background-color': '#f0f0f0'}">
                <el-table-column property="accountName" label="拨打坐席" width="100" align="center"></el-table-column>
                <el-table-column property="callStatus" label="接通状态" width="120" align="center"></el-table-column>
                <el-table-column property="dailTime" label="拨打时间" width="150" align="center"></el-table-column>
                <el-table-column property="timeCount" label="拨打时长" width="120" align="center"></el-table-column>
                <el-table-column property="dispose_status" label="沟通备注" width="120"
                                 align="center"></el-table-column>
                <el-table-column label="操作" width="270" align="center">
                    <audio controls="controls">
                        <!--            <source src="https://call.qinglanx.com:8443/pscc/sounds/record/${item.recordingUrl}" type="audio/ogg">-->
                        <!--            <source src="https://call.qinglanx.com:8443/pscc/sounds/record/${item.recordingUrl}" type="audio/mpeg">-->
                    </audio>
                    <!--          <img dynsrc="https://call.qinglanx.com:8443/pscc/sounds/record/${item.recordingUrl}" />-->
                </el-table-column>
            </el-table>
        </el-dialog>

        <TheAddOpportunity
            :pond-id="1"
                @reload="reload"
                :subject-list="subjectList"
                :possess-list="usePossessList"
                :is-show-add-oppor="isShowAddOppor">
        </TheAddOpportunity>

        <GgdShowTelephone :opportunityId="showTelephoneOpporId" :showTelephoneObj="isShowTelephone"></GgdShowTelephone>

        <el-drawer
                size="60%"
                ref="rightDrawer"
                v-if="drawer"
                :visible.sync="drawer"
                @close="handleDrawerClose"
                :with-header="false">
            <span style="margin: 10px"></span>
            <el-tabs v-model="activeName" type="card" @tab-click="tabsClick">
                <el-tab-pane label="学员信息" name="opporInfo">
                    <Detail :id="opportunityId"
                            :drawer-obj="drawerObj"
                            @myOpportunity="myOpportunity"
                            @closeTheDrawer="closeTheDrawer"
                            @showInnerDrawer="showInnerDrawer"></Detail>
                    <el-drawer
                            title="学习记录"
                            size="40%"
                            :append-to-body="true"
                            :visible.sync="innerDrawer">
                        <LearnDetail :opportunity-id="opportunityId"></LearnDetail>
                    </el-drawer>
                </el-tab-pane>
                <el-tab-pane label="历史订单" name="orderHistory">
                    <div v-for="orderItem in orderList" style="background-color: #efeeee;padding: 20px 30px 0 30px">
                        <el-row style="margin-bottom: 10px">
                            订单编号: {{ orderItem.orderNumber }}
                        </el-row>
                        <el-row style="margin-bottom: 10px">
                            <el-col :span="12">
                                商品名称:{{ orderItem.goodsName }}
                            </el-col>
                            <el-col :span="12">
                                订单状态: {{ orderItem.status === 1 ? '已成交' : '未支付' }}
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom: 10px">
                            <el-col :span="12">
                                订单创建时间: {{ orderItem.createTime }}
                            </el-col>
                            <el-col :span="12">
                                订单支付时间:{{ orderItem.paymentTime }}
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                缴费金额(已交): {{ orderItem.paymentAmount }}
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>
                    </div>
                    <pagination
                            :total="orderParams.total"
                            :page.sync="orderParams.current"
                            :page-sizes="[5,10,15,20]"
                            :limit.sync="orderParams.limit"
                            @pagination="findOrders"
                    />
                </el-tab-pane>
            </el-tabs>
        </el-drawer>

        <TheQuestionBack :show-obj="isShowQuestionBack"></TheQuestionBack>
        <ClearStream :show-obj="isShowClearStream"></ClearStream>

        <el-dialog :title="historyTitle" :visible.sync="historyOpen" width="80%" append-to-body>
            <HistoryRecord></HistoryRecord>
        </el-dialog>
        <QuickAccessKnowledge :suffix="suffix" :knowledgeObj="knowledgeObj"></QuickAccessKnowledge>
        <WakeBank v-if="isShowWakeBankObj.isShow"
                  :pond-id="1"
                  @reload="reload"
                  :show-wake-bank-obj="isShowWakeBankObj"></WakeBank>

        <el-dialog :destroy-on-close="true" append-to-body
                   title="补录订单" :visible.sync="repairShow" width="80%" :fullscreen="true">
            <OrderRepairList :pond-id="pondId"></OrderRepairList>
        </el-dialog>
        <el-dialog
                title="问题反馈"
                :visible.sync="feedbackDialogVisible"
                width="60%">

            <el-divider content-position="left"><span
                    style="color: #F56C6C">此入口反馈的所有问题处理优先级最高，所有反馈均会在1周内给出回复.</span>
            </el-divider>

            <el-button type="primary" plain
                       @click="feedbackQuestion(1)">匿名投诉入口
            </el-button>

            <el-divider content-position="left"><span
                    style="color: #909399">此入口问卷为匿名状态，处理人无法看到投诉人。班主任各项细节问题均可反馈</span>
            </el-divider>
            <el-button type="warning" plain
                       @click="feedbackQuestion(2)">
                教学资料·错误问题登记
            </el-button>
            <el-button type="warning" plain
                       @click="feedbackQuestion(3)">crm内bug问题登记
            </el-button>

            <el-divider/>

            <el-button type="info" plain
                       @click="feedbackQuestion(4)">历史问题反馈处理状况
            </el-button>

            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="feedbackDialogVisible = false">关 闭</el-button>
      </span>
        </el-dialog>

        <el-dialog title="机会回库" :visible.sync="backSync">
            <el-row v-for="item in backData">
                <el-col :span="12">机会ID:{{ item }}</el-col>
            </el-row>
            <el-row style="margin: 20px 0">
                <b style="color: #dd6161;font-size: 20px">请选择回库原因:</b>
            </el-row>
            <el-row>
                <el-radio-group v-model="backIntention">
                    <el-radio-button v-for="data in intentionDatas" :label="data.label" border></el-radio-button>
                </el-radio-group>
            </el-row>
            <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="backSync = false">关 闭</el-button>
        <el-button type="success" @click="batchGiveUp">确 定</el-button>
      </span>
        </el-dialog>

        <AddWechat ref="addWeChatDialog" :data-message="addWeChatMessage"></AddWechat>
        <OpportunityListBack
                :show-obj="importObj.showObj"
                @getList="doSearch"></OpportunityListBack>

        <CreateCouponShare
                v-if="createCouponShareDialogVisible"
                :createCouponShareDialogVisible="createCouponShareDialogVisible"
                @createCoupon="createCoupon"
        ></CreateCouponShare>
    </div>
</template>

<script>
import QuickAccessKnowledge from "@/components/QuickAccessKnowledge";
import GgdCondition from "../../components/GgdCondition"
import WakeBank from "./components/WakeBank";
import * as searchApi from "@/api/system/searchDeploy";
import * as api from "../../utils/api"
import * as analysisApi from "@/api/crm/data/data-analysis"
import AV from 'leancloud-storage'
import * as dictApi from "@/api/system/dictionary";
import History from "@/components/details/History";
import * as repairApi from "@/api/crm/orderRepair"
import * as receiveApi from '@/api/system/receive'
import AddWechat from "@/views/consultant/components/AddWechat.vue";
import {copy} from "@/utils/utils";
import OpportunityListBack from "@/views/consultant/components/OpportunityListBack.vue";
import * as tagsApi from "@/api/crm/tags";
import LearnDetail from "@/components/details/LearnDetail.vue";
import CreateCouponShare from "@/components/details/CreateCouponShare.vue"
import * as ordersApi from "@/api/crm/orders";
import {todayCache} from "../../api/crm/data/data-analysis";

const GgdCard = () => import("../../components/GgdCard");
const GgdShowTelephone = () => import("../../components/GgdShowTelephone")

const TheAddOpportunity = () => import("../../components/opportunity/TheAddOpportunity")
const Detail = () => import("../../components/details/Detail")
const TheQuestionBack = () => import("../../components/TheQuestionBack");
const HistoryRecord = () => import("../../components/historyRecord")
const ClearStream = () => import("../../components/ClearStream")
const OrderRepairDetail = () => import("../../components/order/OrderRepairDetail.vue")
const OrderRepairList = () => import("@/components/order/OrderRepairList.vue")

export default {
    components: {
        LearnDetail,
        OpportunityListBack,
        AddWechat,
        WakeBank,
        TheQuestionBack,
        TheAddOpportunity,
        GgdCondition,
        GgdCard,
        GgdShowTelephone,
        Detail,
        History,
        HistoryRecord,
        QuickAccessKnowledge,
        ClearStream,
        OrderRepairDetail,
        OrderRepairList,
        CreateCouponShare
    },
    data() {
        return {
            analysisData: {
                revenue: 0,
                calledTotal: 0,
                called: 0,
                receiveFirst: 0,
                receiveInventory: 0,
                repairRevenue: 0,
            },
            activeName: "opporInfo",
            createCouponShareDialogVisible: false,
            typeOptions: ['', 'success', 'warning', 'danger', 'info'],
            tagsOptions: [],
            intentionDatas: [],
            backIntention: "",
            backData: [],
            backSync: false,
            typeList: ['primary', 'success', 'warning', 'danger'],
            repairAmount: 0,
            repairShow: false,
            tableData: [],
            tabData: '',
            sortRule: {prop: null, order: null},
            newClientData: [], //抢首咨表格
            checkOpporData: [], //机会校验的列表
            subjectListData: [],
            multipleSelection: [],
            condition: {}, // 搜索条件
            page: {current: 1, size: 10, total: 0},
            dayAccount: 0, //今日流水
            sumTime: "00:00:00", //拨打时长
            dailUp: 0, //拨打量
            stockCount: 0,  //库存数
            newClient: 0, //首咨数
            showClient: false,//领取首咨的弹出框
            newCount: 0, //今日还可领取的首咨数量
            showCheckOppor: false, //机会校验的弹出框
            showCheckOpporDetail: false, //机会校验详情的弹出框
            showCheckOpporDetailOrder: [],//机会校验详情订单
            showCheckOpporDetailAppoint: [],//机会校验详情预约
            showCheckOpporDetailCall: [],//机会校验详情沟通列表
            checkOpporTelephone: "", //机会校验输入框中的内容
            isShowTelephone: {
                isShow: false
            },
            showTelephoneOpporId: 0,
            isShowAddOppor: {
                isShow: false
            },//添加机会弹出框
            isShowQuestionBack: {
                isShow: false
            },
            isShowClearStream: {
                isShow: false
            },
            //打开唤醒机会库
            isShowWakeBankObj: {
                isShow: false
            },
            drawer: false,//右侧抽屉
            innerDrawer: false,//右侧子抽屉
            opportunityId: -1, //机会Id
            subjectList: [],
            possessList: [],
            usePossessList: [],
            opporTypeRadios: '',
            loading: true,
            searchList: [],
            columns: [],
            operates: {},
            mediators: [],
            historyOpen: false,
            historyTitle: "历史数据",
            drawerObj: {},
            suffix: '',
            knowledgeObj: {
                isShow: false,
                link: ''
            },
            callDouble: {},//双呼按钮
            callPanshi: {},//磐石云按钮
            subjectObj: {},
            SearchNewInformationList: [],
            isZebj: false,
            chooseId: 0,
            isShowOperation: false,
            myBtnList: [],
            withinTwoDayBackNum: null,
            withinTodayBackNum: null,
            feedbackDialogVisible: false,
            message: {
                title: '',
                id: '',
            },
            addWeChatMessage: {},
            importObj: {
                showObj: {
                    isShow: false
                }
            },
            mainInlet: '', // 主要领取入口 1:自创建 2:机会校验领取 3:领取库存
            orderList: [],
            orderParams: {
                total: 0,
                current: 1,
                limit: 5
            },
            trafficUser: null,
            pondId: 1,
        }
    },
    methods: {
        formateTime(time) {
            const h = parseInt(time / 3600)
            const minute = parseInt(time / 60 % 60)
            const second = Math.ceil(time % 60)

            const hours = h < 10 ? '0' + h : h
            const formatSecond = second > 59 ? 59 : second
            return `${hours > 0 ? `${hours}:` : ''}${minute < 10 ? '0' + minute : minute}:${formatSecond < 10 ? '0' + formatSecond : formatSecond}`
        },
        getTrafficUser() {
            this.trafficUser = localStorage.getItem('trafficUser')
        },
        findOrders() {
            this.orderParams['opporId'] = this.opportunityId

            ordersApi.listOpporOrders(this.orderParams).then(res => {
                this.orderList = res.data.data
                this.orderParams['total'] = res.data.total
                // this.orderParams['current'] = res.data.current
            })
        },
        tabsClick(tab, event) {
            console.log(tab, event);
        },
        createCoupon(res) {
            this.createCouponShareDialogVisible = res && res === true;
        },
        showInnerDrawer(opportunityId) {
            this.innerDrawer = true;
            this.opportunityId = opportunityId;
        },
        splitTags(tags) {
            return tags?.split(",").filter(e => e && e.trim()).map(Number) || []
        },
        findTagsOptions() {
            tagsApi.options().then(res => {
                this.tagsOptions = res.data
            })
        },
        copyWechat(id) {
            api.getTelephone(id).then(resp => {
                if (resp.success) {
                    let telephone = resp.msg
                    if (telephone !== null) {
                        copy(telephone);
                        this.$message.success("复制成功!")
                    }
                } else {
                    this.$message.error(resp.msg)
                }
            }).catch(e => {
                this.$message.error("复制失败!" + e)
            })
        },
        addWeChat(row) {
            this.addWeChatMessage = Object.assign({}, row)
            this.$refs.addWeChatDialog.openDialog()
        },
        findIntention() {
            receiveApi.findOptionCode('intention').then(res => {
                this.intentionDatas = res.data
            })
        },
        openBackSync(id) {
            this.backData = id ? [id] : this.multipleSelection
            if (!this.backData) {
                this.$message.warning("请至少选择一行数据")
                return
            }
            this.backSync = true
        },
        /**
         * 批量回库
         */
        batchGiveUp() {
            if (!this.backIntention) {
                this.$message.error("请选择回库原因!");
                return
            }
            let params = {"intention": this.backIntention}
            api.giveMessageBack(this.backData, params).then(res => {
                if (res.success) {
                    this.$message.success("操作成功")
                    this.backSync = false
                    this.reload()
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("批量回库失败");
            })
        },
        openNoticePopup() {
            this.$store.dispatch('changeShopMessageObj', {
                isShow: true,
                type: 2,
                id: this.message.id
            })
            this.message.isRead = 1;
            window.localStorage.setItem("message-new", JSON.stringify(this.message))
        },
        getMyBtn() {
            receiveApi.my().then(res => {
                if (res.success) {
                    this.myBtnList = res.data
                    this.mainInlet = res.msg
                }
            })
        },
        /**
         * 补录订单流水信息
         */
        repair() {
            repairApi.getCount().then(res => {
                this.repairAmount = this.numberformat(res.data.money / 100)
            })
        },
        //金额格式化
        numberformat(number) {
            number = (number + '').replace(/[^0-9+-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+2) ? 0 : Math.abs(2),
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.floor(n * k) / k;
                };
            s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join('.');
        },
        /**
         * 订单补录
         */
        repairOrder() {
            this.$router.push({
                path: '/crm/order-repair',
                query: {
                    isShowBack: '1'
                }
            })
            // this.repairShow = true
        },
        showOperation(id) {
            this.chooseId = id
            this.isShowOperation = true
        },
        zebj() {
            var dataKey = document.cookie.replace(/(?:(?:^|.*;\s*)dataKey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
            if (dataKey === 'zhengerbajing') {
                this.isZebj = true
            }
        },
        quickAccessKnowledge(name, subjectName) {
            let objectId = this.subjectObj[subjectName]
            this.suffix = `&name=${name}`
            if (objectId) {
                this.suffix += `&type=${objectId}`
            }
            if (name === 'speechcraft') {
                this.knowledgeObj.link = 'http://wechat.front.igegeda.com/smooth-communication/knowledge_phone/knowledge_new.html'
                window._hmt.push(['_trackEvent', '按钮点击', '电销话术按钮链接点击']);
            } else if (name === 'teachers') {
                this.knowledgeObj.link = 'http://wechat.front.igegeda.com/smooth-communication/knowledge/teacherIntroduction.html'
                window._hmt.push(['_trackEvent', '按钮点击', '师资介绍按钮链接点击']);
            } else if (name === 'course') {
                this.knowledgeObj.link = 'http://wechat.front.igegeda.com/smooth-communication/knowledge/courseIntroduction.html'
                window._hmt.push(['_trackEvent', '按钮点击', '商品介绍按钮链接点击']);
            }
            this.knowledgeObj.isShow = true
        },
        //获取渠道列表
        getMediators() {
            api.allMediators().then(res => {
                this.mediators = res.data
            });
        },
        handleSizeChange(val) {
            this.page.size = val
            this.reload()
        },
        handleCurrentChange(val) {
            this.page.current = val
            this.reload()
        },
        selectRow(select, row) {
            this.multipleSelection.push(row.id)
        },
        selectAll(selection) {
            this.multipleSelection = selection.map(res => {
                return res.id
            })
        },
        /**
         * 取消选择
         */
        selectChange(selection) {
            this.multipleSelection = selection.map(res => {
                return res.id
            })
        },
        /**
         * 获取头部信息
         */
        getBaseInformation() {
            analysisApi.todayCache().then(res => {
                console.log('todayCache', res)
                this.analysisData = res.data
            })
            // api.baseInformation().then(res => {
            //     if (res.success) {
            //         let resEntity = res.data
            //         this.dayAccount = resEntity.dayAccount
            //         this.sumTime = resEntity.sumTime
            //         this.dailUp = resEntity.dailUp
            //         this.stockCount = resEntity.stockCount
            //         this.newClient = resEntity.newclient
            //     } else {
            //         this.$message.warning(res.msg)
            //     }
            // }).catch(err => {
            //     this.$message.error("获取头部信息失败")
            // })
        },
        /**
         * 打开领取首咨弹窗
         */
        loadNewClient() {
            this.showClient = false
            api.clientList(1).then(res => {
                if (res.success) {
                    let resEntity = res.data
                    this.newCount = resEntity.newCount
                    this.newClientData = resEntity.newList
                    this.showClient = true
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                this.$message.error("获取首咨列表失败")
            })
        },
        /**
         * 抢首咨
         */
        getNewOpportunity(e, id) {
            if (this.newCount <= 0) {
                return this.$message.warning("今日领取首咨数量已经超过上限")
            }

            api.receiveOpportunity(id, 1).then(res => {
                if (res.success) {
                    let target = e.target
                    let tagName = target.tagName
                    if (tagName === 'SPAN') {
                        target = target.parentNode
                    }
                    target.innerText = "已领取"
                    target.classList.replace("el-button--warning", "el-button--primary")
                    this.newCount--
                    this.newClient++
                    this.$message.success(res.msg)
                    this.reload()
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                this.$message.error("抢首咨失败")
            })
        },
        /**
         * 校验机会内容
         */
        checkOppor() {
            if (!this.checkOpporTelephone) {
                this.$message.warning("机会校验输入框中内容不可为空")
                return
            }
            let telephone = this.checkOpporTelephone.trim()
            telephone = telephone.replace("​", "")
            api.checkOpportunityList(telephone, this.pondId).then(res => {
                if (res.success) {
                    this.checkOpporData = res.data
                    this.showCheckOppor = true
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                this.$message.error("抢首咨失败")
            })
        },
        /**
         * 查看手机号
         */
        showTelephone(id) {
            this.showTelephoneOpporId = id
            this.isShowTelephone.isShow = true
        },
        myOpportunity(params) {
            api.myOpportunity(this.page.current, this.page.size, params).then(res => {
                if (res.success) {
                    let resEntity = res.data
                    this.page.current = resEntity.current
                    this.page.size = resEntity.size
                    this.page.total = resEntity.total
                    this.tableData = resEntity.records
                    for (let i in this.tableData) {
                        if (this.tableData[i].extra) {
                            let tson = JSON.parse(this.tableData[i].extra)
                            this.tableData[i].tson = tson
                        }
                    }
                    this.tabData = resEntity.records
                    if (this.sortRule.order !== null && this.sortRule.prop != null) {
                        this.handleSortChange(this.sortRule)
                    }
                    if (this.opporTypeRadios === '8') {
                        this.withinTwoDayBackNum = resEntity.total
                    } else if (this.opporTypeRadios === '9') {
                        this.withinTodayBackNum = resEntity.total
                    }
                } else {
                    this.$message.warning(res.msg);
                }
                this.loading = false
            }).catch(err => {
                this.loading = false
                this.$message.error("获取我的机会失败");
            })

        },
        /**
         * 获取配置
         * */
        Index() {
            api.index().then(res => {
                this.callPanshi = res.data.callPanshi;
                this.callDouble = res.data.callDouble;
            })
        },
        /**
         * 双呼拨打
         */
        callDoubleing(e, id) {
            api.doubleCall(id).then(res => {
                if (res.success) {
                    //隐藏当前点击的拨打按钮，显示挂断按钮
                    e.target.innerText = '已呼叫'
                    this.$message.success(res.msg)
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.warning("拨打失败");
            })
        },
        /**
         * 拨打
         */
        calling(e, id) {
            api.calling(id).then(res => {
                if (res.success) {
                    //隐藏当前点击的拨打按钮，显示挂断按钮
                    let target = e.target
                    let tagName = target.tagName
                    if (tagName === 'SPAN') {
                        target = target.parentNode
                    }
                    let next = target.nextSibling;
                    target.setAttribute("hidden", true)
                    next.removeAttribute("hidden")
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.warning("拨打失败");
            })
        },
        /**
         * 挂断
         */
        hangup(e) {
            api.hangUp().then(res => {
                if (res.success) {
                    //隐藏当前点击的挂断按钮，显示拨打按钮
                    let target = e.target
                    let tagName = target.tagName
                    if (tagName === 'SPAN') {
                        target = target.parentNode
                    }
                    let prev = target.previousSibling;
                    target.setAttribute("hidden", true)
                    prev.removeAttribute("hidden")
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("挂断失败");
            })
        },
        /**
         * 搜索条件搜索
         */
        doSearch(params) {
            this.page.current = 1
            this.condition = params
            this.loading = true
            this.myOpportunity(params)
        },
        /**
         * 机会类型搜索
         */
        doSearchByType: function (type) {
            console.log(type, 1)
            this.condition['type'] = null
            this.condition['backStoreTimeStart'] = null
            this.condition['backStoreTimeEnd'] = null
            this.condition['acquisitionMethod'] = null
            this.condition['appointmentTime'] = null
            this.condition['notAddWechat'] = null
            switch (type) {
                case "":
                case "1" :
                case "2":
                case "3":
                case "4":
                    this.condition['type'] = type
                    break
                case "5":
                    //查询分配数据
                    this.condition['acquisitionMethod'] = 2;
                    break
                case "6":
                    this.condition['appointmentTime'] = new date()
                    break
                case "8":
                    const t = (new Date).getTime() + (2 * 86400000);
                    const times = new Date(t);
                    //2天内回库
                    this.condition['backStoreTimeStart'] = this.formatDate(new Date())
                    this.condition['backStoreTimeEnd'] = this.formatDate(times)
                    break
                case "9":
                    //今日回库
                    this.condition['backStoreTimeStart'] = this.formatDate(new Date())
                    this.condition['backStoreTimeEnd'] = this.formatDate(new Date(), true) + " 23:59:59"
                    break
                case "7":
                    this.condition['type'] = 5
                    break
                case "10":
                    this.condition['notAddWechat'] = 0
                    break
                default:
                    break;
            }
            console.log(this.condition, 2)
            this.doSearch(this.condition)
        },
        formatDate(date, showHour) {
            var year = date.getFullYear();
            var month = date.getMonth() + 1
            var day = date.getDate()
            var hour = date.getHours()
            var minute = date.getMinutes()
            var second = date.getSeconds()
            var dateStr = year + "-" + (month < 10 ? '0' + month : month) + "-" + (day < 10 ? '0' + day : day)
            if (!showHour) {
                dateStr += " " + (hour < 10 ? '0' + hour : hour) + ":" + (minute < 10 ? '0' + minute : minute) + ":" + (second < 10 ? '0' + second : second);
            }
            return dateStr
        },
        /**
         * 打开
         */
        openAddOppor() {
            this.getUsePossessList()
            this.isShowAddOppor.isShow = true
        },
        /**
         * 打开唤醒机会库
         */
        openWakeOpportunityBank() {
            this.isShowWakeBankObj.isShow = true
        },
        /**
         * 获取唯一值配置
         */
        getPossessList() {
            api.possessList().then(res => {
                let list = res.data
                if (document.cookie.indexOf("zhengerbajing") > 0) {
                    list = list.filter(function (item) {
                        return item.id !== 1
                    })
                }
                this.possessList = list
            })
        },
        /**
         * 获取咨询师页面添加机会唯一值配置
         */
        getUsePossessList() {
            api.usePossessList().then(res => {
                let list = res.data
                if (document.cookie.indexOf("zhengerbajing") > 0) {
                    list = list.filter(function (item) {
                        return item.id !== 1
                    })
                }
                this.usePossessList = list
            })
        },
        /**
         * 打开右侧抽屉
         */
        openOpporDetail(id, index) {
            this.drawer = true
            this.opportunityId = id
            let idList = this.tableData.map(m => {
                return m.id
            })
            this.drawerObj.idList = idList
            this.drawerObj.index = index
            this.findOrders()
        },
        /**
         * 关闭右侧抽屉
         */
        closeTheDrawer: function () {
            this.drawer = false
        },
        toRouter(name, params) {
            this.$store.dispatch('message/clearMessageType13Id', params.id)
            this.$store.dispatch('setHeaderTitle', params.name)
            this.$router.push({
                name: name,
                params: params
            })
        },
        onCopy(e) {    // 复制成功
            this.$message({
                message: '复制成功！',
                type: 'success'
            })
        },
        onError(e) {   // 复制失败
            this.$message({
                message: '复制失败！',
                type: 'error'
            })
        },
        /**
         * 获取专业列表
         */
        getSubjectList: function () {
            api.subjectList().then(res => {
                if (res.success) {
                    this.subjectList = res.data
                }
            })
        },
        getSearchList: function () {
            searchApi.findByClassify("CONSULTANT_SEARCH").then(res => {
                this.searchList = res.data;
                for (let item of this.searchList) {
                    if (item.data) {
                        let data = JSON.parse(item.data)
                        item['options'] = data
                    }
                }
            });
        },
        getSearchNewInformationList: function () {
            searchApi.findByClassify("NEW_INFORMATION").then(res => {
                for (let t of res.data) {
                    let tson = JSON.parse(t.data)
                    t["tson"] = tson
                }
                this.SearchNewInformationList = res.data;
            });
        },
        getColumn: function () {
            searchApi.findByClassify("CONSULTANT_TABLES").then(res => {
                this.columns = res.data;
            });
        },
        getOperates: function () {
            searchApi.findByClassify("CONSULTANT_OPERATE").then(res => {
                let result = res.data;
                let tmp = {}
                for (let i in result) {
                    tmp[result[i].name] = true
                }
                this.operates = tmp
            });
        },
        /**
         * 问题反馈
         */
        questionBack: function () {
            // http://wechat.front.igegeda.com/smooth-communication/approval.html?userid=${userId}&url=${url}
            this.isShowQuestionBack.isShow = true

        },
        /**
         * 问题反馈
         */
        clearStream: function () {
            // http://wechat.front.igegeda.com/smooth-communication/approval.html?userid=${userId}&url=${url}
            // this.isShowClearStream.isShow = true
            // let urlPrefix = window.location.host
            // let name = getUserName()
            // window.open(`https://wenjuan.feishu.cn/m?t=sByZKzu2MiMi-pdem&qfchannel=${urlPrefix}_${name}`, '_blank', 'toolbar=no,location=no,status=no, width=900, height=700,top=100, left=100')
            this.feedbackDialogVisible = true
        },
        feedbackQuestion(type) {
            switch (type) {
                case 1:
                    // window.open(`https://sytp32wcdz.feishu.cn/base/W5jjbG0GQaKHRbs6zMKcFfXRnfb`);
                    window.open(`https://wenjuan.feishu.cn/m?t=sByZKzu2MiMi-pdem`);
                    break;
                case 2:
                    window.open(`https://sytp32wcdz.feishu.cn/sheets/BzDQsPZd8hzj8StAHftc7WWbnBc?from=from_parent_bitable`);
                    break;
                case 3:
                    window.open(`https://sytp32wcdz.feishu.cn/sheets/Y7W9skpi5h9IE7tsNPicuitrnec`);
                    break;
                case 4:
                    window.open(`https://sytp32wcdz.feishu.cn/base/Lx6MbIpBhaEIAPsF235cDOelnGb`);
                    break;
                case 5:
                    window.open(`https://sytp32wcdz.feishu.cn/sheets/OuwTs4alSh2lmvtbfhTctbbQnMc?from=from_copylink`);
                    break;
                case 6:
                    window.open(`https://sytp32wcdz.feishu.cn/docx/LE29dNlXDootKhxWFUdc3icZnDh`);
                    break;
            }
        },
        isShowCheckOpporDetail: function (opportunityId) {
            this.showCheckOpporDetail = true
            api.opportunityDetail(opportunityId).then(res => {
                if (res.success) {
                    let resEntity = res.data
                    this.showCheckOpporDetailOrder = resEntity.orderHistory//机会校验详情订单
                    //     showCheckOpporDetailAppoint:[],//机会校验详情预约
                    this.showCheckOpporDetailCall = resEntity.callHisotry//机会校验详情沟通列表
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        handleSortChange: function (column) {
            // dailTime  executeTime collectionTime
            let columnName = column.prop
            if (column.order !== null) {
                let data = []
                for (let i = 0; i < this.tableData.length; i++) {
                    //判断如果是空的 push在数字后面，有值的放在前面，然后把排好的数级再同仁给表格data
                    if (this.tableData[i][columnName]) {
                        data.unshift(this.tableData[i])
                    } else {
                        data.push(this.tableData[i])
                    }
                }
                this.tableData = data
            }
            //如果不排序，恢复到最初未排序的状态
            if (column.order === null) {
                this.tableData = this.tabData
            }
            //把当前排序的规则记录下来，给翻页时提供了默认排序条件
            this.sortRule.order = column.order
            this.sortRule.prop = column.prop
        },
        /**
         * 领取机会
         */
        drawOppor(e, id) {
            this.$confirm("您是否要领取该名片?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: 'warning'
            }).then(() => {
                api.draw(id, 2).then(res => {
                    if (res.success) {
                        let target = e.target
                        let tagName = target.tagName
                        if (tagName === 'SPAN') {
                            target = target.parentNode
                        }
                        target.innerText = "已领取"
                        target.classList.replace("el-button--warning", "el-button--primary")
                        this.stockCount++
                        this.$message.success(res.msg)
                        this.reload()
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(error => {
                    this.$message.error("请求失败,请稍后重试!")
                })
            })
        },
        /**
         * 抽屉关闭时的回调方法
         */
        handleDrawerClose() {
            this.reload()
        },
        /**
         * 重新刷新页面
         * @param params
         */
        reload() {
            this.loading = true
            this.myOpportunity(this.condition)
        },
        showData(row, name) {
            return row[name];
        },
        handleRowClassName({row, index}) {
            if (row.orderStatus === 1) {
                return "order-success"
            }
            if (row.type === 5) {
                return "oppor-waken"
            }
        },
        historyRecord() {
            this.historyOpen = true
        },
        initLeanCloudSubject() {
            const query = new AV.Query('Index');
            query.get('5fd0357f29e82736b63f1821').then((todo) => {
                let data = todo.toJSON()
                let entity = data.entity;
                entity.forEach(e => {
                    let name = e.name
                    let objectId = e.objectId
                    this.subjectObj[name] = objectId
                })
            });
        },


        /**
         * 额外参数
         * 获取字典数据
         * */
        /*
          * 获取字段字段
          * */
        getdictionaryList(id) {
            dictApi.findParent(id).then((res) => {
                if (res) {
                    this.dictionaryList = res.data
                }
            })
        },
        /*
        * 如果监听到message数据存在,执行回调
        * */
        // initMessageParams() {
        //     if (this.messageParams.type === 'gotoConsultant') {
        //         this.$store.getters['message/callBack'].bind(this)()
        //     }
        // }
    }
    ,
    computed: {
        /**
         * 领取机会总数
         * @returns {number}
         */
        opporTotalCount: function () {
            return this.newClient + this.stockCount
        }
        ,
        newClientTitle: function () {
            return `您今日还可领取首咨数为${this.newCount}个`
        }
        ,
        userId: function () {
            return 0
        }
        ,
        // messageParams() {
        //   return this.$store.getters['message/messageParams']
        // }
        messageType_13_id() {
            return this.$store.getters['message/messageType_13_id']
        }
    }
    ,
// watch: {
//     messageParams: {
//         deep: true,
//         handler: function (n) {
//             this.initMessageParams()
//         }
//     }
// },
    filters: {
        ellipsis(value) {
            if (!value) return ''
            if (value.length > 8) {
                return value.slice(0, 8) + '...'
            }
            return value
        }
    }
    ,
    mounted() {
        this.getTrafficUser()
        this.zebj()
        this.getBaseInformation()
        this.myOpportunity()
        this.getPossessList()
        this.getSubjectList()
        this.getSearchList()
        // this.getSearchNewInformationList()
        this.getColumn()
        this.getOperates()
        this.getMediators()
        this.initLeanCloudSubject()
        this.Index()
        this.repair()
        this.getMyBtn()
        this.findIntention()
        this.findTagsOptions()
        // this.initMessageParams()
        setInterval(() => {
            let message = window.localStorage.getItem("message-new")
            if (message) {
                message = JSON.parse(message);
                if (message.id !== this.message.id) {
                    this.message = message
                    // this.message.title = message.title
                    // this.message.notifyId = message.notifyId
                }
            } else message = '{}'
        }, 3000)
    }
    ,
}

</script>

<style scoped lang="less">

.top-container {
  background: #FFFFFF;
}

/deep/ .el-drawer__body {
  overflow: auto;
  /* overflow-x: auto; */
}

/deep/ .el-drawer /deep/ .rtl {
  overflow: scroll
}

.function-button {
  height: 62px;
  display: flex;
  align-items: center;
}

.clearStream {
  margin: 0 15px 0 0 !important;
  background-color: #fb9337;
  border: none;
  border-radius: 4px !important;
  width: 132px;
  height: 50px;
  font-size: 16px;
  margin-top: 5px !important;
}

.el-table th > .cell {
  display: inline-block;
  white-space: nowrap;
  word-break: keep-all;
  text-overflow: unset;
}

/deep/ .telephone {
  cursor: pointer;
}

/deep/ .order-success {
  background: url("../../assets/icon/order_success.png") no-repeat left 0px top 0px;
  background-size: 40px;
  background-color: #FFFFFF;
}

/deep/ .oppor-waken {
  background: url("../../assets/icon/oppor_waken.png") no-repeat left 0px top 0px;
  background-size: 40px;
  background-color: #FFFFFF;
}

/deep/ .el-table__body tr.hover-row > td {
  background-color: transparent !important;
}


.red {
  color: red;
}

.green {
  color: green;
}

.position {
  position: relative;
}

.positionBottom {
  display: inline-block;
  position: absolute;
  bottom: -2px;
  cursor: pointer;
}

/deep/ .el-button--success {
  color: #FFF;
  background-color: #2f8e00;
  border-color: #2f8e00;
}

.WeChatIcon {
  color: #43C420;
  font-weight: bolder;
  font-size: 18px;
  vertical-align: middle;
}

.item {
  margin-top: 10px;
  margin-right: 30px;
}


.top-tool-btn-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: row;
    max-width: 50vw;
    padding-left: 5px;
  }

  .right {
    display: flex;
    flex-direction: row;

    ._btn-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      height: fit-content;


      :nth-child(n) {
        margin-bottom: 10px !important;
      }
    }

  }

}

</style>
